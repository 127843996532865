<template>
  <div id="app">
    <div class="container">
      <vue-scratchable
        v-slot="{ init }"
        :brushOptions="brush"
        :hideOptions="hide"
        getPercentageCleared
        @percentage-update="updatePoints"
      >
        <div class="wrapper">
          <img
            :src="require('./assets/bottom.jpg')"
            @load="init()"
          >
        </div>
      </vue-scratchable>
    </div>

    <div class="alert">
      <img :src="require('./assets/phone.svg')"/>
      <span>Для удобства переверните устройство</span>
    </div>
  </div>
</template>

<script>
import VueScratchable from './components/vue-scratchable.vue';
import paperPattern from './assets/top.jpg';

export default {
  name: 'App',
  components: {
    VueScratchable,
  },
  computed: {
    subline() {
      return this.percentage < 100
        ? `🎉 There is still ${100 - this.percentage}% left for me to be free... 🎉`
        : '💚 Thank you for scratching me free! 💚';
    },
  },
  data() {
    return {
      percentage: 0,
      hide: {
        type: 'pattern',
        src: paperPattern,
      },
      brush: {
        size: window.innerWidth > 1000 ? 90 : 60,
        shape: 'round',
      },
    };
  },
  methods: {
    updatePoints(percentage) {
      this.percentage = percentage;
    },
    redrawImage() {
      const canvas = document.getElementsByTagName('canvas')[0];
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = paperPattern;

      img.onload = () => {
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const imgWidth = img.width;
        const imgHeight = img.height;

        const scale = Math.min(canvasWidth / imgWidth, canvasHeight / imgHeight);

        const scaledImage= this.scaleIt(img,1 / scale);

        setTimeout(() => {
          ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
          document.querySelector('#app').style.opacity = '1';
        }, 0);
      };
    },
    scaleIt(source, scaleFactor){
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      const w = source.width * scaleFactor;
      const h = source.height * scaleFactor;
      c.width = w;
      c.height = h;
      ctx.drawImage(source,0,0,w,h);
      return(c);
    }
  },
  mounted() {
    this.redrawImage();
  },
  created() {
    window.addEventListener('resize', () => {
      location.reload();
    });
  },
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020E1C;
  opacity: 0;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: min(calc(100vh * (16 / 9)), 1280px);
  max-width: min(calc(100dvh * (16 / 9)), 1280px);
  max-height: min(calc(100vw * (9 / 16)), 720px);
  background-color: #ccc;
  overflow: hidden;
}

.wrapper, .wrapper img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.alert {
  pointer-events: none;
  padding: 20px;
  display: none;
  background-color: #020E1C;
  position: absolute;
  z-index: 3;
  inset: auto 0 0 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  fill: #FFFFFF;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.alert img {
  width: 24px;
  height: auto;
}

@media only screen and (max-device-width : 768px) and (orientation : portrait) {
  .alert {
    display: flex;
  }
}
</style>
